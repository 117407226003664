import React from 'react'
import Layout from '@/components/layout'
import SolutionItVendorHeader from '@/components/SolutionPages/ItVendor/SolutionItVendorHeader'
import ItVendorProblemSolution from '@/components/SolutionPages/ItVendor/ItVendorProblemSolution'
import { TestimonialSection } from '@/components/TestimonialSection'
import { PartnersSection } from '@/components/PartnersSection'
import { StopStartSection } from '@/components/StopStartSection'
import { Footer } from '@/components/Footer'
import Benefits from '@/components/SolutionPages/Benefits'
import WhatDoYouGainVendor from '@/components/SolutionPages/ItVendor/WhatDoYouGainVendor'
import { graphql, useStaticQuery } from 'gatsby'
import ItVendorWhatToDo from '@/components/SolutionPages/ItVendor/ItVendorWhatToDo'

const Itvendor: React.FC = () => {
  const {
    allContentfulSubPagesTitles: { nodes },
  } = useStaticQuery(graphql`
    {
      allContentfulSubPagesTitles {
        nodes {
          itVendorPage
        }
      }
    }
  `)

  const { itVendorPage } = nodes[0]
  return (
    <Layout title={itVendorPage}>
      <SolutionItVendorHeader />
      <ItVendorProblemSolution />
      {/*<TestimonialSection />*/}
      <PartnersSection />
      <WhatDoYouGainVendor />
      <ItVendorWhatToDo />
      <Benefits />
      <StopStartSection />
      <Footer />
    </Layout>
  )
}

export default Itvendor
