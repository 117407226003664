import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SolutionHeader from '@/components/SolutionPages/SolutionHeader'

const SolutionItVendorHeader: React.FC = () => {
  const {
    allContentfulSolutionPage: { nodes },
  } = useStaticQuery(graphql`
    {
      allContentfulSolutionPage(
        filter: { solutionName: { eq: "vendor" } }
      ) {
        nodes {
          mainTitle
          mainText {
            mainText
          }
        }
      }
    }
  `)

  const { mainTitle, mainText } = nodes[0]

  return <SolutionHeader title={mainTitle} text={mainText.mainText} />
}

export default SolutionItVendorHeader
