import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import richTextOptions from '@/utils/richTextOptions'
import ContentContainer from '@/components/UI/ContentContainer'
import SemiTitle from '@/components/UI/SemiTitle'

const Wrapper = styled(ContentContainer)`
  background-color: ${({ theme }) => theme.colors.lightGray};
  align-items: center;
  justify-content: center;
`

const Title = styled(SemiTitle)`
  text-align: center;
`

const Text = styled.div`
  line-height: 24px;
  max-width: 480px;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    text-align: left;
  }

  p {
    margin-bottom: 20px;
  }
`
const StyledImage = styled.div<{ reverse?: boolean }>`
  img {
    max-width: 335px !important;

    @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
      max-width: 430px !important;
      min-width: 430px !important;
    }
  }
  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    margin-inline: ${({ reverse }) => (reverse ? '10% 0' : '0 10%')};
  }
`

const Content = styled.div<{ reverse?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  flex-direction: ${({ reverse }) =>
    reverse ? 'column-reverse' : 'column'};

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    flex-direction: row;
  }
`
const ItVendorProblemSolution: React.FC = () => {
  const {
    allContentfulSolutionPage: { nodes },
  } = useStaticQuery(graphql`
    {
      allContentfulSolutionPage(
        filter: { solutionName: { eq: "vendor" } }
      ) {
        nodes {
          problemTitle
          text1 {
            raw
          }
          solutionTitle
          text3 {
            raw
          }
          text4 {
            raw
          }
        }
      }
    }
  `)
  const { problemTitle, text1, solutionTitle, text3, text4 } = nodes[0]

  const richText1 = renderRichText(text1, richTextOptions)
  const richText3 = renderRichText(text3, richTextOptions)
  const richText4 = renderRichText(text4, richTextOptions)

  return (
    <Wrapper>
      <div>
        <Title
          data-sal="slide-down"
          data-sal-duration="1000"
          data-sal-easing="easeOutQuad"
        >
          {problemTitle}
        </Title>

        <Content reverse>
          <Text
            data-sal="zoom-in"
            data-sal-duration="1000"
            data-sal-easing="easeOutQuad"
          >
            {richText1}
          </Text>
          <StyledImage
            reverse
            style={{ marginBottom: 30 }}
            data-sal="fade"
            data-sal-duration="1000"
            data-sal-easing="easeOutQuad"
          >
            <StaticImage
              src={'../../../assets/VendorSolution/problem.png'}
              alt="mockedImage"
              width={430}
              placeholder={'blurred'}
            />
          </StyledImage>
        </Content>
      </div>
      <div style={{ marginTop: 100 }}>
        <Title
          data-sal="slide-down"
          data-sal-duration="1000"
          data-sal-easing="easeOutQuad"
        >
          {solutionTitle}
        </Title>

        <Content reverse>
          <Text
            data-sal="zoom-in"
            data-sal-duration="1000"
            data-sal-easing="easeOutQuad"
          >
            {richText3}
          </Text>
          <StyledImage
            reverse
            style={{ marginBottom: 30 }}
            data-sal="fade"
            data-sal-duration="1000"
            data-sal-easing="easeOutQuad"
          >
            <StaticImage
              src={'../../../assets/VendorSolution/solution.png'}
              alt="mockedImage"
              placeholder={'blurred'}
            />
          </StyledImage>
        </Content>
        <Content>
          <StyledImage
            style={{ marginBottom: 20 }}
            data-sal="fade"
            data-sal-duration="1000"
            data-sal-easing="easeOutQuad"
          >
            <StaticImage
              src={'../../../assets/VendorSolution/solution2.png'}
              alt="mockedImage"
              placeholder={'blurred'}
            />
          </StyledImage>
          <Text
            data-sal="zoom-in"
            data-sal-duration="1000"
            data-sal-easing="easeOutQuad"
          >
            {richText4}
          </Text>
        </Content>
      </div>
    </Wrapper>
  )
}

export default ItVendorProblemSolution
