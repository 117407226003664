import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import richTextOptions from '@/utils/richTextOptions'
import ContentContainer from '@/components/UI/ContentContainer'

const Wrapper = styled(ContentContainer)`
  background-color: ${({ theme }) => theme.colors.lightGray};
  align-items: center;
  justify-content: center;
`

const Text = styled.div`
  line-height: 24px;
  max-width: 480px;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    text-align: left;
  }

  p {
    margin-bottom: 20px;
  }
`

const Content = styled.div<{ reverse?: boolean }>`
  display: flex;
  justify-content: center;
  margin-top: 60px;
  flex-direction: ${({ reverse }) =>
    reverse ? 'column-reverse' : 'column'};

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    flex-direction: row;
  }
`
const ItVendorWhatToDo: React.FC = () => {
  const {
    allContentfulWhatToDo: { nodes },
  } = useStaticQuery(graphql`
    {
      allContentfulWhatToDo {
        nodes {
          whatWeDo {
            raw
          }
          whatYouDo {
            raw
          }
        }
      }
    }
  `)
  const { whatWeDo, whatYouDo } = nodes[0]

  const richTextWhatWeDo = renderRichText(whatWeDo, richTextOptions)
  const richTextWhatYouDo = renderRichText(whatYouDo, richTextOptions)

  return (
    <Wrapper>
      <div>
        <Content reverse>
          <Text
            data-sal="zoom-in"
            data-sal-duration="1000"
            data-sal-easing="easeOutQuad"
          >
            {richTextWhatWeDo}
          </Text>
          <Text
            data-sal="zoom-in"
            data-sal-duration="1000"
            data-sal-easing="easeOutQuad"
          >
            {richTextWhatYouDo}
          </Text>
        </Content>
      </div>
    </Wrapper>
  )
}

export default ItVendorWhatToDo
